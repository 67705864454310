<template>
  <div class="success_container">
    <img src="@/static/images/my_index/ok.png" alt="">
    <p class="result">感谢您耐心认真的评价，请静待结果</p>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
.success_container {
  text-align: center;
  img {
    margin-top: 40%;
  }
}
.result {
  color: #f00;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 220px;
}
</style>
